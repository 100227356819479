.loader {
    margin: auto;
    border: 16px solid #5bc0de;
    border-radius: 50%;
    border-top: 10px solid #0275d8;
    border-right: 10px solid #5cb85c;
    border-bottom: 10px solid #d9534f;
    border-left: 10px solid #f0ad4e
    ;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }